<template lang="html">
  <div class="card card-primary card-outline">
    <div class="card-header">
      <h3 class="card-title">我的资料</h3>
      <div class="card-tools mr-0"></div>
    </div>

    <!-- /.box-header -->
    <div class="card-body p-0 p-sm-4">
      <div
        v-if="loading"
        style="flex: auto; display: flex; text-align: center; justify-content: center"
      >
        <div>
          <i class="fas fa-spinner fa-pulse"></i>
          <p>载入中…</p>
        </div>
      </div>
      <div v-else class="row">
        <div class="col-md-6">
          <!-- Profile Image -->
          <div class="card card-primary card-outline">
            <div class="card-body box-profile">
              <div class="text-center">
                <router-link :to="{ name: 'Avatar' }">
                  <img
                    class="profile-user-img img-fluid img-circle"
                    :src="user.avatarUrl"
                    alt="User profile picture"
                  />
                </router-link>
              </div>

              <h3 class="profile-username text-center">{{ user.nickName }}</h3>

              <p class="text-muted text-center">{{ user.userName }}</p>

              <ul class="list-group list-group-unbordered mb-3">
                <li class="list-group-item">
                  <b>邮箱：</b>{{ user.email }}
                  <span class="float-right">
                    <template v-if="user.email"
                      ><span class="ml-1" v-if="user.emailConfirmed">已验证</span
                      ><router-link v-else class="ml-1" :to="{ name: 'ProfileEdit' }"
                        >未验证</router-link
                      > </template
                    ><router-link :to="{ name: 'ProfileEdit' }" v-else
                      >未设置</router-link
                    >
                  </span>
                </li>
                <li class="list-group-item">
                  <b>手机：</b>{{ user.phoneNumber }}
                  <span class="float-right"
                    ><template v-if="user.phoneNumber"
                      ><span class="ml-1" v-if="user.phoneNumberConfirmed">已验证</span
                      ><router-link
                        v-else
                        class="ml-1"
                        :to="{ name: 'ConfirmPhoneNumber' }"
                        >未验证</router-link
                      > </template
                    ><router-link :to="{ name: 'ProfileEdit' }" v-else
                      >未设置</router-link
                    >
                  </span>
                </li>
                <li class="list-group-item">
                  <b>公众号：</b
                  ><span class="float-right"
                    >{{ user.wxSubscribe ? "已关注" : "未关注" }}
                  </span>
                </li>
                <li class="list-group-item">
                  <b>会员：</b>
                  <span v-if="$inRole('VIP')"> VIP会员 </span>
                  <span v-if="$inRole('测试')"> 测试会员 </span>
                  <span v-if="$inRole('大像素')"> 大像素会员 </span>
                  <a
                    v-else
                    href="javascript:"
                    @click="
                      $router.push({
                        name: 'Buy',
                        params: { productId: 20002001 },
                        query: {},
                      })
                    "
                  >
                    开通大像素会员
                  </a>
                </li>
              </ul>

              <router-link :to="{ name: 'ProfileEdit' }" class="btn btn-primary mr-1"
                ><b>修改资料</b></router-link
              >
              <router-link :to="{ name: 'ChangePassword' }" class="btn btn-primary mr-1"
                ><b>修改密码</b></router-link
              >
            </div>
            <!-- /.card-body -->
          </div>
          <!-- /.card -->
        </div>
        <div class="col-md-6">
          <div class="card card-primary">
            <div class="card-header">
              <strong><i class="fas fa-book mr-1"></i> 简介</strong>
            </div>
            <div class="card-body">
              <div class="text-muted" v-html="user.summary"></div>
            </div>
          </div>
          <div class="card card-primary">
            <div class="card-header">
              <strong><i class="fas fa-qrcode mr-1"></i> 赞赏码</strong>
            </div>
            <div class="card-body">
              <div class="mb-1">
                <UploadBigFileBox
                  action="/Api/My/Users/UploadPraise"
                  :multiple="false"
                  :maxSize="1"
                  :maxWidth="2000"
                  :maxHeight="2000"
                  :compress="false"
                  :isPano="false"
                  :allowExtensions="/(.jpg|.jpeg|.png|.gif)$/i"
                  :partSizeMB="5"
                  :surlheight="100"
                  :surlwidth="200"
                  :maxUpper="1"
                  :limit="1"
                  @uploadSuccess="logoUploadFinished"
                >
                </UploadBigFileBox>
              </div>
              <template v-if="user.praiseQRCode">
                <img class="img-fluid" :src="user.praiseQRCode.url" />
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- /.box-body -->
  </div>
</template>

<script>
import { UploadSingleBox, UploadBigFileBox } from "common";

export default {
  components: {
    UploadSingleBox,
    UploadBigFileBox,
  },
  data() {
    return {
      user: {},
      loading: false,
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      var url = "/Api/My/Users";
      this.loading = true;
      this.$axios
        .get(url)
        .then((response) => {
          console.log(response);
          this.user = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    logoUploadFinished(file) {
      console.log(file);
      this.user.praiseQRCode = file;
    },
  },
};
</script>

<style lang="css" scoped></style>
